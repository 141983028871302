.footer {
  padding-top: 50px;
  padding-bottom: 30px;
  background: #fde4e4;
}

.footer__logo img {
  width: calc(100% - 80%);
  margin-bottom: 5px;
}

.footer__logo h5 {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}

.footer__logo p {
  font-size: 0.9rem;
  color: #333;
  line-height: 28px;
}

.delivery__time-item {
  background: transparent !important;
}

.delivery__time-item span {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
}

.delivery__time-item p {
  color: #212245;
}
.footer__title {
  font-size: 1.1rem;
  color: #212245;
  font-weight: 600;
}

.newsletter {
  padding: 5px;
  border: 1px solid #212245;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.newsletter input {
  background: transparent;
  border: none;
  outline: none;
}

.newsletter input:focus {
  outline: none;
}

.newsletter span {
  background: #df2020;
  padding: 5px 30px;
  border-radius: 5px;
}
.link-red{
  color: #df2020;
 
  font-size: 1rem;
  text-decoration: underline;
}
.link-red:hover{
  color: #df2020;
 
  font-size: 1rem;
  text-decoration: underline;
}
.newsletter span i {
  color: #fff;
}

.newsletter input::placeholder {
  color: #212245;
}

.social__links span i {
  padding: 5px;
  background: #df2020;
  color: #fff;
  border-radius: 50%;
}

.copyright__text {
  font-size: 0.8rem;
  color: #df2020;
}

@media only screen and (max-width: 992px) {
  .newsletter input {
    padding: 7px;
    font-size: 0.7rem;
  }
  .newsletter span {
    padding: 4px 20px;
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .footer__title {
    font-size: 0.8rem;
  }

  .delivery__time-item span {
    font-size: 0.8rem;
  }

  .delivery__time-item p {
    font-size: 0.7rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .copyright__text {
    text-align: center;
  }

  .social__links p {
    font-size: 0.8rem;
  }

  .social__links span {
    padding: 4px;
  }
  .social__links span a {
    font-size: 0.7rem;
  }
}
