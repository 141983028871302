.hero-section {
  position: relative;
  padding: 3rem 0;
  /* background-image: url('../assets//images/banner.png'); */
  background-color: #df2020;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 588px;
  clip-path: 
  polygon(
    0 0,           /* Top left */
    100% 0,         /* Top right */
    100% calc(100% - 50px), /* Right side with wave dip */
    50% 100%,       /* Bottom middle point */
    0 calc(100% - 50px) /* Left side with wave dip */
  );
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 35, 35, 0.0);
  z-index: 1;
}

.hero-content,
.hero-search,
.hero-download {
  position: relative;
  z-index: 2;
}
.hero-download{
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 70px;
}
.hero-title,
.hero-download-title,
.hero-download-subtitle
{
  color: #f6f7f8 !important;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.hero-subtitle {
  color: #555;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.address-input {
  background-color: rgba(255, 255, 255, 0.9);
  border-color: var(--primary);
}

.hero-download-title {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-download-subtitle {
  color: #555;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}


.header {
  width: 100%;
  height: 70px;
  line-height: 50px;
}

.logo {
  text-align: center;

}
.logo img {
  width: calc(100% - 60%);
  object-fit: contain;
  /* margin-top: -40px; */
}

.logo h5 {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  margin-top: -40px;
  margin-bottom: 0;
}

.menu a {
  font-weight: 600;
  font-size: 1rem;
  color: #212245;
  transition: 0.3s;
}

.menu a:hover {
  color: #df2020;
}

.active__menu {
  color: #df2020 !important;
}

.cart__icon i,
.user i,
.mobile__menu i {
  font-size: 1.3rem;
  color: #212245;
  cursor: pointer;
}

.cart__icon {
  position: relative;
}

.cart__badge {
  position: absolute;
  top: 30px;
  right: -10px;
  background: #df2020;
  color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile__menu {
  font-weight: 600;
  display: none;
}

.header__shrink {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
  box-shadow: 5px 5px 15px -5px #fde4e4;
}

@media only screen and (max-width: 992px) {
  .logo{
    padding-top: 0px;
  }

  .logo h5 {
    font-size: 0.8rem;
    margin-top: -15px;
  }

  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.425);
    z-index: 99;
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    line-height: 20px;
  }

  .menu a {
    font-size: 0.9rem;
  }

  .mobile__menu {
    display: block;
  }

  .cart__icon i,
  .user i,
  .mobile__menu i {
    font-size: 1rem;
  }

  .show__menu {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
    line-height: 80px;
  }


  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle,
  .hero-download-subtitle {
    font-size: 1rem;
  }
 
  .logo h5 {
    margin-top: -55px;
  }
}



