.contact{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:50px;
    flex-direction: column;
}
.delete-title{
    font-size:1.2rem;
    color:red;

}